import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { CustomAlertComponent } from "../_shared-components/alert/alert.component";
import { BehaviorSubject } from 'rxjs';
import { StorageService } from "./storage.service";
import { DomSanitizer } from "@angular/platform-browser";
declare var FM: any;


@Injectable({
    providedIn: 'root',
})

export class GlobalService {

    private bannerBoolean = new BehaviorSubject<boolean>(this.storageService.getItems('banner') ? this.storageService.getItems('banner') : false);
    currentMessage = this.bannerBoolean.asObservable();

    constructor(private http: HttpClient,private sanitizer: DomSanitizer, private storageService: StorageService) {
        //this.getImage();
    }

    alerts: any[] = [];
    loading = false;
    imageMap = new Map();
    imageProfileMap = new Map();

    @Output() getStarted = new EventEmitter<string>();

    /* Alert Start */
    addAlert(type: string, message: string): void {
        // primary, secondary, success, danger, warning, info, light, dark
        let duplicate = false;
        if (this.alerts.length > 0) {
            this.alerts.forEach((element => {
                if (element.msg === message) {
                    duplicate = true;
                    return;
                }
            }));
        }

        if (!duplicate) {
            this.alerts.push({
                type: type,
                msg: message,
                timeout: 3000
            })

        }

    }

    onClosed(dismissedAlert: CustomAlertComponent): void {
        this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
    }
    /* Alert End */

    /* Loader Start */
    showLoader() {
        return this.loading = true;
    }

    hideLoader() {
        return this.loading = false;
    }
    /* Loader End */

    getStartedClick(type: string) {
        this.getStarted.emit(type);
    }

    getAddressToken() {
        this.http.get(`${environment.API_URL}employer/getaddresstoken`).subscribe((res: any) => {
            const token = JSON.parse(res.body);
            this.storageService.setItems("addressToken", token.access_token);
        });
    }

    trackCustomEvent(title, email, visitor = false) {
        //FM.associateVisitor(email);
        FM.trackCustomEvent(title, {
            "email": email,
            "return date": new Date()
        });
    }

    getImage(){
       let user = this.storageService.getItems("userDetails");
        this.http.get(`${environment.API_URL}user/getimageid?id=${user.id}`).subscribe((res:any) => {
            this.imageProfileMap.set(user.id,this.sanitizer.bypassSecurityTrustUrl(res.image));
          })
      }

    contactForm(data) {
        return this.http.post(`${environment.API_URL}user/contactus`, data);
    }

    setBanner(data: boolean) {
        this.storageService.setItems("banner", data);
        this.bannerBoolean.next(data)
    }

    getBanner() {
        return this.bannerBoolean.getValue();
    }
}