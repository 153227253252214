<div class="bg-solitude pt-25 pt-md-30 pb-13 pb-xxl-32 career-page mx-n6 mx-lg-0">
    <div class="container px-6 px-lg-0 mw-100 w-90">
        <div class="row bg-white p-md-9 p-4 justify-content-center career-page-wrap mw-px-849 mx-auto">
            <h2 class="heading w-100 font-size-30px">Careers</h2>
            <div class="sub-heading w-100 mr-auto pb-7">Thanks for checking out our job openings. See something that interests you? Apply here.</div>
            <div class="jobs-container w-100 d-none">
                <div class="job-list border-bottom">
                    <span class="job-role font-weight-semibold font-size-18px">
                        Ads Coordinator
                        <span class="job-type d-md-none font-size-13px">Full-time</span>
                    </span>
                    <span class="job-location font-size-13px">Auckland</span>
                    <span class="job-type font-size-13px d-none d-md-inline-block">Full-time</span>
                    <span class="job-details"></span>
                </div>
                <div class="job-list border-bottom">
                    <span class="job-role font-weight-semibold font-size-18px">
                        Marketing Maneger
                        <span class="job-type d-md-none font-size-13px">Full-time</span>
                    </span>
                    <span class="job-location font-size-13px">Auckland</span>
                    <span class="job-type font-size-13px d-none d-md-inline-block">Full-time</span>
                    <span class="job-details"></span>
                </div>
                <div class="job-list border-bottom">
                    <span class="job-role font-weight-semibold font-size-18px">
                        UI Designer
                        <span class="job-type d-md-none font-size-13px">Part-time</span>
                    </span>
                    <span class="job-location font-size-13px">Auckland</span>
                    <span class="job-type font-size-13px d-none d-md-inline-block">Part-time</span>
                    <span class="job-details"></span>
                </div>

            </div>
            <div class="empty-jobs">
                <img src="../assets/image/png/search-empty.png" alt="" class="w-100 px-lg-15 px-md-10 px-6">
                <h2 class="font-size-20px font-weight-bold text-center" style="color: #6B6E6F;">Oops looks like we dont have any positions posted.</h2>
            </div>
            <div class="sub-heading2 w-100 mt-3"></div>
            <div class="future-oppo mt-13">
                <h3 class="font-size-20px font-weight-semibold mb-6">Future Opportunities in New Zealand</h3>
                <span class="mb-d">Are you keen to work at Temp but we don't currently have a role advertised that suits your skills, experience or area of interest? If that sounds like you, please apply here so that we can have your details on file - if a suitable job comes up we may be in contact!</span>
            </div>
            <div class="d-flex flex-row justify-content-start w-100 mt-8">
                <img src="../../../assets/image/svg/question-answer-line-green.svg">
                <div class="d-flex flex-column ml-7 font-weight-bold">
                    <div>Contact us at</div>
                    <div [cdkCopyToClipboard]="email" style="color: #00B074;cursor: pointer;">careers@temp.org.nz</div>
                </div>
            </div>
        </div>
    </div>
</div>