<div class="bg-white pt-25 pb-13 pb-xxl-32 legal-page">
  <div class="container w-90">
    <div class="row justify-content-center legal-page-wrap mw-px-972 mx-auto">
      <h2 class="font-size-8 font-weight-bold mr-auto">Legal</h2>
      <div class="legal-dd dropdown d-md-none">
        <button class="btn btn-outline-primary dropdown-toggle" type="button" data-toggle="dropdown">
          PAYMENTS
          <span class="dropdown-arrow"></span>
        </button>
        <ul class="dropdown-menu">
          <li><a routerLink="../terms-conditions" routerLinkActive="active">TERMS & CONDITIONS</a></li>
          <li><a routerLink="../privacy-policy" routerLinkActive="active">PRIVACY POLICY</a></li>
          <li><a routerLink="../payments" routerLinkActive="active">PAYMENTS</a></li>
        </ul>
      </div>
      <div>
        <tabset>
          <tab heading="TERMS & CONDITIONS" id="terms-conditions-tab">
            <span class="updated-date py-6">Last updated 24/03/2023</span>
            <p>
              These Terms and Conditions constitute a legally binding agreement
              made between you, whether personally or on behalf of an entity
              (&ldquo;you&rdquo;) and Temp (&ldquo;we,&rdquo; &ldquo;us&rdquo;
              or &ldquo;our&rdquo;), concerning your access to and use of the
              Temp platform as well as any other media form, media channel,
              mobile website or mobile application related, linked, or otherwise
              connected thereto (collectively, the &ldquo;Site&rdquo;).
            </p>

            <!--/Section 0 Preamble/-->
            <h2><strong>Preamble</strong></h2>
            <ul>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  Temp provides an online platform to facilitate Temporary jobs.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  The Job provider/business wishes to engage Temp to find
                  workers and schedule jobs as outlined in a job brief.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  This agreement sets out the terms between the 3 parties
                  involved. Temp, the Worker, and the Job provider
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  By agreeing to these terms, the parties confirm that they are
                  bound by this Agreement and any amended term of this Agreement
                  that is subsequently changed in accordance with the terms of
                  this Agreement.
                </p>
              </li>
            </ul>

            <p>
              <span style="font-size: 24pt"><strong>1. Definition and interpretations as defined in this
                  agreement.</strong></span>
            </p>
            <p>
              <span style="font-size: 18pt"><strong>1.1 Definition</strong></span>
            </p>

            <p dir="ltr">
              <strong>Agreement:</strong> Means this agreement including any
              document signed by the Parties that varies or supplements it in
              accordance with its terms.
            </p>
            <p dir="ltr">
              <strong>Worker: </strong>Means a person engaged to provide
              services operating as an independent contractor by a Job provider
              on the Temp platform. This does not include workers of Temp
              Limited.
            </p>
            <p dir="ltr">
              <strong>Job provider:</strong> Means you are operating as a Job
              provider looking to engage a worker for temporary work
              services.&nbsp;
            </p>
            <p dir="ltr">
              <strong>Business: </strong>Means the job provider who is looking
              to hire using the Temp platform
            </p>
            <p dir="ltr">
              <strong>Temporary:</strong> Refers to the job duration and the
              nature of the work completed through the Temp platform. This can
              be an ongoing engagement between the worker and the Job provider
              that has no end date, providing both parties agree to the
              terms.&nbsp;&nbsp;
            </p>
            <p dir="ltr">
              <strong>Job:</strong> Is what is created by the Job provider and
              completed by the worker.&nbsp;
            </p>
            <p dir="ltr">
              <strong>Temp platform:</strong> Means any system, website or
              platform provided by Temp (including the Temp Site) to facilitate
              the engagement between workers and Job providers.
            </p>
            <p dir="ltr">
              <strong>Temp: </strong>Means Temp Limited and is referred to in
              this Agreement as Temp or the company
            </p>
            <p dir="ltr">
              <strong>Job Brief/Job description:</strong> This outlines the
              details in terms of what is required for the worker to undertake
              the job.
            </p>
            <p dir="ltr">
              <strong>Hourly rate: </strong>Defines the remuneration for each
              hour of work completed.&nbsp;
            </p>
            <p dir="ltr">
              <strong>Work:</strong> Means services performed or provided by a
              worker at the work site in response to a Job Brief.
            </p>
            <p dir="ltr">
              <strong>Service fee:</strong> Meaning Temp&rsquo;s percentage
              calculated based on the job brief. This may change based on the
              Job providers subscription plan.
            </p>
            <p dir="ltr">
              <strong>Work site: </strong>Means any location, premises or
              building at which a worker is, will or has worked for the purposes
              of providing the Work.
            </p>
            <p dir="ltr">
              <strong>Representative:</strong> Means any director, officer,
              employee, agent, contractor, or other person representing the Job
              provider.
            </p>
            <p>
              <strong>Licenses and qualifications:</strong>&nbsp;Temp does not
              verify or investigate the authenticity of any prerequisites added
              under the Licenses and qualification section. This feature is
              simply an advertising feature and by no means a reliable method of
              validation. It is up to the Job provider to decide the
              authenticity of the workers licenses or qualifications.
            </p>

            <p>
              <span style="font-size: 18pt"><strong>1.2 Interpretations</strong></span>
            </p>

            <p dir="ltr">
              In this Agreement, except where the context otherwise
              requires:&nbsp;
            </p>
            <ol type="A">
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  words in the singular form also include their plural form, and
                  vice versa, and words denoting a gender also include other
                  genders.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  alternative grammatical forms of defined terms have
                  corresponding meanings.&nbsp;
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  any reference to money is in New Zealand currency.&nbsp;
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  any reference to time is in accordance with New Zealand
                  Daylight Time or New Zealand Standard Time (as applicable).
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  any reference to a Party includes their executors,
                  administrators, successors, and permitted assigns and
                  substitutes.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  any reference to a person includes individuals, partnerships,
                  corporations, associations, government entities, or other
                  legal entities.&nbsp;
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  any reference to a statute, ordinance, code, or other law
                  includes regulations and other instruments made under it, and
                  any consolidation, amendment, re-enactment, or replacement of
                  them.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  the meaning of general words is not limited by specific
                  examples provided with phrases like "including", "for
                  example", or similar expressions.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  headings are for convenience only and do not affect the
                  interpretation of the agreement.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  if a Party comprises of multiple persons, the agreement binds
                  each of them separately and any two or more of them
                  jointly.&nbsp;
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  any obligation, representation, or warranty made in favour of
                  multiple persons is for their joint and collective
                  benefit.&nbsp;
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  any agreement, representation, warranty or indemnity made in
                  favour of two or more Parties (including where two or more
                  persons are included in the same defined term) is for their
                  joint and several benefit.&nbsp;
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  no rule of construction shall be interpreted to the
                  disadvantage of a Party merely because they were responsible
                  for preparing this Agreement or any part of it.
                </p>
              </li>
            </ol>
            <!--/Section 2 General/-->
            <p>
              <span style="font-size: 18pt"><strong>2.1. User Accounts</strong></span><br />To use the Temp platform,
              you must register and maintain an
              active user account with Temp (in its capacity as licensor of the
              Temp branded application and websites) (&ldquo;Account&rdquo;) (as
              described in section 3 (&lsquo;Contractual relationship) of the
              Temp Terms). Your failure to maintain accurate, complete, and
              up-to-date Account information, including having an invalid or
              expired payment method on file, may result in your inability to
              access or use the Temp platform. If information provided is found
              to be misleading or false, Temp may immediately terminate (as
              described in clause 13) the account and any future involvement
              with the party at Temps sole discretion.
            </p>

            <!--/Section 3 Contractual relationship/-->

            <p>
              <span style="font-size: 24pt"><strong>3. Contractual relationship</strong></span><br /><span
                style="font-size: 18pt"><strong>3.1. Temp services</strong></span><br />Temp will provide services in
              accordance with these terms
              and conditions. Temp Services constitute the provision of a
              technology platform that enables you, as a user of Temp&rsquo;s
              applications or websites (each, an &ldquo;Application&rdquo;) to:
              (a) arrange and schedule certain jobs with independent third-party
              providers of those services that have an agreement with Temp or
              its affiliates (&ldquo;Third Party Providers&rdquo;), including:
              (i) Job providers providing the job opportunity and workers who
              are providing the work services.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>3.2. Obligations as a worker</strong></span><br />By applying for a
              job, the worker accepts that they are
              entering into an agreement with the Business (the job provider) to
              uphold the terms and conditions outlined in this agreement.
            </p>
            <p>
              <strong>3.2.1. </strong>As a worker on the Temp platform, you
              acknowledge that when you apply for a job it is at the sole
              discretion of the Job provider whether to hire you or not. Once
              you have applied for a job you accept that you have committed to
              the dates set forth in the job description. Failure to turn up to
              an already accepted job without valid reason may result in a ban
              from the Temp platform. This is at the sole discretion of Temp
              which will carry out an investigation after the job period has
              ended. Temp will only investigate issues that have been reported.
              Workers must ensure they are of legal age required to complete the
              job. This includes being at least 18 years or older when working
              with Alcohol and at least 21 years of age when working on a
              premises with gambling facilities.
            </p>
            <p>
              <strong>3.2.2.</strong> As a worker you operate as an independent
              contractor which means you have no guarantee of work, and the
              business (the job provider) is not obligated to handle any Tax,
              ACC, or employment benefit on your behalf. You are obligated by
              law to pay tax on all your earnings that you have made by using
              the Temp platform. Temp does not deduct tax from workers earnings.
              Workers are to make their own arrangements to pay tax on earnings
              made through the Temp platform.
            </p>
            <p>
              <strong>3.2.3. </strong>Workers are not employees of Temp nor are
              they paid by Temp. Temp acts as a digital software provider that
              connects Job providers and workers.
            </p>
            <p>
              <strong>3.2.4.</strong> Workers agree that they must complete the
              job to the best of their ability.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>3.3. Obligations as a Job provider</strong></span><br />By posting a
              job the Job provider agrees that they are
              entering into an agreement with the worker that is set forth in
              this agreement.
            </p>
            <p><strong>The Job provider must:</strong></p>
            <p>
              <strong>3.3.1.</strong> provide safe and suitable working
              conditions that are in accordance with the Health and Safety at
              Work Act 2015.
            </p>
            <p>
              <strong>3.3.2.</strong> provide Temp with full and accurate
              information about the service requirements relevant to the Job
              Brief, and all other information and assistance reasonably
              necessary to enable Temp to provide services and workers to
              perform their work; This should include the nature of the task in
              which the Job provider is hiring to complete and any safety
              equipment that might be used/needed.
            </p>
            <p>
              <strong>3.3.3. </strong>not allocate tasks or responsibilities to
              the workers or require the workers to perform or participate in
              work other than in accordance with the relevant Job description.
            </p>
            <p>
              <strong>3.3.4. </strong>Immediately advise Temp in writing once it
              becomes aware that the work, role, responsibilities, or work
              conditions the Job provider sets for a worker at the time when the
              work is being performed do not match the role classification that
              the Job provider selected when creating the Job Brief;
            </p>
            <p>
              <strong>3.3.5. </strong>Ensure that workers will be covered by the
              following insurance policies, or other suitable and permissible
              statutory indemnity or self-insurance arrangements, whilst
              performing their Work at the Work Site:
            </p>
            <p style="padding-left: 40px">
              <strong>3.3.5.1.</strong> public liability, professional
              indemnity, if relevant to the type of Work to be performed.
            </p>
            <p style="padding-left: 40px">
              <strong>3.3.5.2. </strong>compulsory third party motor
              vehicle insurance, and fully comprehensive motor vehicle insurance
              (including third party personal injury, own damage, third party
              property damage liability and third party personal injury gap) for
              any vehicles operated by a worker;
            </p>
            <p>
              Failure to meet these requirements or misrepresentation of the
              skill level or workload of the job may result in a ban from the
              Temp platform.
            </p>

            <!--/Section 4 Ratings/-->

            <p>
              <span style="font-size: 24pt"><strong>4. Ratings</strong></span><br />By entering a job as the job
              provider or as the worker you
              acknowledge that you will be rated at the end of the job period.
              Quality of ratings is of the opinion of each party and should
              comply with the terms outlined in this agreement. It is important
              to know that it is not Temps obligation to censor ratings, Temp
              may choose to intervene at its sole discretion if job feedback
              includes inappropriate language or unreasonable criticism.
            </p>

            <p>
              <span style="font-size: 18pt"><strong>4.1. Ratings for Job providers</strong></span><br /><strong>4.1.1
                Rating your worker</strong><br />All ratings
              should be an accurate representation of the workers efforts and
              commitment to the job.
            </p>
            <p>
              <strong>4.1.2. Receiving a rating as a Job provider</strong><br />As a Job provider you accept that by
              hiring through the
              Temp platform you acknowledge you will be rated on your
              performance as a Job provider. Ratings are completely the opinion
              of the workers you hire and what keeps the platform fair. There is
              a possibility for you to receive an unsatisfactory rating from a
              worker and it is not the job of Temp to influence, edit or change
              this unless Temp considers that the rating was unfairly or
              dishonestly provided.
            </p>

            <p>
              <span style="font-size: 18pt"><strong>4.2. Ratings for workers</strong></span><br /><strong>4.2.1.Rating
                your Job provider</strong><br />All
              ratings should be an accurate representation of the Job providers
              efforts to create a fair and suitable work environment.
            </p>
            <p>
              <strong>4.2.2. Receiving a rating as a worker</strong><br />As a
              worker you acknowledge that you will be rated on your performance
              for each job you complete through the Temp platform. While ratings
              are meant to be an accurate representation of your effort and
              commitment. There is a possibility for you to receive an
              unsatisfactory rating. It is not the job of Temp to influence,
              edit or change this unless Temp considers that it was unfairly or
              dishonestly provided.
            </p>

            <!--/Section 5 Disputes/-->

            <p>
              <span style="font-size: 24pt"><strong>5. Disputes</strong></span><br />Any disputes over job quality, pay
              or other contexts are to
              be handled between the business (Job provider) and the worker (the
              services provider). Temp acts as the digital facilitator to
              connect both parties for work opportunities and does not have any
              involvement nor is obligated to participate in any dispute
              resolution.&nbsp;
            </p>
            <p>
              Where there is a dispute between the Job provider and the worker,
              funds that have already been paid to Temp will be refunded to the
              Job provider.
            </p>

            <!--/Section 6. Cancellation of Job Brief by Job provide/-->

            <p>
              <span style="font-size: 24pt"><strong>6. Cancellation of Job Brief by Job
                  provider</strong></span><br />The Job Provider may (i) cancel a Job Brief or any part of
              it, or (ii) make any amendment to the Job Brief which causes the
              shift of one or more worker(s) to be canceled, without any
              liability at any time, provided that such cancellation or
              amendment is done using the Temp Platform, 4 hours prior to the
              expected commencement time of the affected shift(s) by a worker(s)
              under the Job Brief (&ldquo;Acceptable Cancellation
              Period&rdquo;).
            </p>

            <!--/Section 7 Payments/-->

            <p>
              <span style="font-size: 24pt"><strong>7. Payments</strong></span><br /><span
                style="font-size: 18pt"><strong>7.1. Hourly Rates</strong></span><br />unless there is a written
              agreement between Temp and the
              job provider. The Job provider is obligated to pay Temp for each
              Job description an amount equal to the sum of the components
              specified in sections 7.1.1 to 7.1.5.
            </p>
            <p>
              <strong>7.1.1. </strong>The total gross payment to be paid to the
              worker(s) also known at the Worker(s) payment.
            </p>
            <p>
              <strong>7.1.2.&nbsp;</strong>The Service Fee will be calculated by
              multiplying the sum of all components from Clause 7.1.1 by the Job
              providers prescribed rate, unless otherwise agreed in writing by
              Temp.
            </p>
            <p>
              <strong>7.1.3.&nbsp;</strong>(the total sum payable by the Job
              provider under a Job description as set out in 7.1.1 to 7.1.2
              above is collectively known as the &ldquo;Rate&rdquo;).
            </p>
            <p>
              <strong>7.1.4.&nbsp;</strong>The Job provider will be shown an
              estimated rate applicable to the Job description after they have
              filled out the relevant information of a Job description (but
              prior to posting the Job via the Temp Platform). The Job provider
              may request further information from Temp regarding the estimated
              Rate (including its breakdown or calculation). The Job provider
              accepts that the estimated Rate is an approximation based on the
              information provided by the Job provider at the time when the Job
              description is filled out and/ or posted. The actual Rate payable
              by the Job provider will be confirmed after the Services and Work
              by the worker(s) are performed, and/ or after the timesheet is
              submitted and verified pursuant to Clause 7.2.
            </p>

            <p>
              <span style="font-size: 18pt"><strong>7.2. Variation of rate</strong></span>
            </p>
            <p>
              <strong>7.2.1. </strong>The Job provider acknowledges that the
              total workers payment and rate components referred to in Clause
              7.1 are subject to change from time to time as a result of a
              variation of the estimated job duration. The Job provider agrees
              that such changes shall become effective and apply to the Rate
              from the date the changes are applied. Excluding any existing or
              outstanding Job Description that has already been accepted by both
              Parties, Temp will use its best endeavors to notify the Job
              provider of any such expected changes with reasonable notice. The
              Job provider agrees to pay Temp the increased Rate from the
              applicable effective date, including any proportional change to
              the amount of Service Fee as a result of such changes.
            </p>

            <p>
              <span style="font-size: 18pt"><strong>7.3. Subscriptions</strong></span><br />Unless otherwise agreed by
              written notice, the Job provider
              is responsible for paying Temp the subscription fee that
              corresponds with the subscription plan they have selected.
            </p>
            <p>
              <strong>7.3.1. Subscriptions Fees and payment</strong><br />Immediately upon receipt of the invoice, the
              Job provider
              gives authorization to Temp to bill their credit card upon
              subscription for the Service and any subsequent renewals. Payment
              obligations may not be canceled within the 1 year subscription
              period. Subscription fees paid are non-refundable. The Job
              provider will pay the Fees using an accepted payment method as
              specified in the invoice or on Temp&rsquo;s website. Unless
              otherwise indicated, the Job Provider's subscription to the
              services will automatically renew for a Subscription Term in
              accordance with the renewal terms and conditions set forth in
              clause 7.3.5. During the Term of the Subscription Period, the Job
              Provider is not permitted to reduce their subscription plan.
            </p>
            <p>
              <strong>7.3.2. Late Payments.</strong><br />If undisputed Fees are
              more than thirty (30) days overdue, then following written
              notification from Temp, Temp may suspend the Job provider&rsquo;s
              access to the Temp platform, including, without limitation, the
              Job provider&rsquo;s account, until such unpaid fees are paid in
              full.
            </p>
            <p>
              <strong>7.3.3. Term, Termination and Suspension</strong><br />Subscriptions are effective at the date of
              enrollment in
              any subscription plan on the Temp website and will continue for a
              period of 1 year.
            </p>
            <p>
              <strong>7.3.4. Subscription plans</strong><br />All plans are
              contracted for 1 year from the date of purchase. Job providers can
              choose to pay the yearly cost upfront to save 10% percent off the
              total subscription fee. Temp may choose to additionally offer
              further discounts at its sole discretion.
            </p>
            <p dir="ltr"><strong>Available plans</strong></p>
            <ol>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  Basic Plan is $50 monthly, plus a 10% service fee.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  Agency Plan is $75 monthly, plus a 5% service fee
                </p>
              </li>
            </ol>
            <p dir="ltr">
              After the Job provider has entered into a subscription plan, they
              will not be eligible to switch to an annual subscription plan for
              any savings.
            </p>
            <p dir="ltr">
              A Basic Plan can be upgraded to an Agency plan at any time.
              Payment will be adjusted accordingly within the next billing
              cycle. If the Basic plan was prepaid annually then the Job
              provider will be charged for the remaining months within the
              current contract at the Agency annual rate. e.g., if the Job
              provider upgrades with 6 months left of their Basic annual plan to
              an Agency annual plan, then they would be charged $135.
            </p>
            <p dir="ltr">
              <strong>7.3.5. Renewal.&nbsp;</strong><br />The Subscription Plans
              will automatically renew for a period equal to the previous
              Subscription Term unless a party provides written notice of
              non-renewal at least thirty (30) days prior to the expiration of
              the current term. At the beginning of each Subscription Term, Temp
              may increase the Fees, and any fees for a renewed term will be due
              on the date of renewal.
            </p>

            <p>
              <span style="font-size: 18pt"><strong>7.4 Refunds</strong></span><br />Refunds will only be given for
              worker discrepancies or
              unfulfilled jobs and will not be given for subscriptions
              regardless of the usage.
            </p>
            <p>
              <strong>7.4.1. Refund for worker discrepancies</strong><br />Refunds will be issued if the worker does not
              fulfill the
              total agreed hours. The Job provider will accurately record the
              workers hours in the Timesheet. (Clause 8)
            </p>
            <p>
              <strong>7.4.2.&nbsp;</strong>Refunds will also be automatically
              sent if the job is not filled in the posting period. Refunds will
              be paid out within 10 days of the end of the posting period.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>7.5. Payouts</strong></span><br />Payment processing services for
              Workers on Temp are
              provided by Stripe and are subject to the
              <span style="color: rgb(0, 176, 116)"><a style="color: rgb(0, 176, 116)" target="_blank"
                  href="https://stripe.com/connect-account/legal/full">Stripe Connected Account Agreement</a>,</span>
              which includes the
              <span style="color: rgb(0, 176, 116)"><a style="color: rgb(0, 176, 116)" target="_blank"
                  href="https://stripe.com/legal">Stripe Terms of Service
                </a> </span>(collectively, the &ldquo;Stripe Services Agreement&rdquo;). By
              agreeing to [this agreement / these terms / and so on] or
              continuing to operate as a Worker on Temp, you agree to be bound
              by the Stripe Services Agreement, as the same may be modified by
              Stripe from time to time. As a condition of Temp enabling payment
              processing services through Stripe, you agree to provide Temp
              accurate and complete information about you and your business, and
              you authorize Temp to share it and transaction information related
              to your use of the payment processing services provided by Stripe.
            </p>
            <p>
              Payouts will be made on a weekly basis if the job extends longer
              than one week. In the case that the job is shorter than one week,
              the payout will be made at the end of the job. The approval of
              payouts is held by the Job Provider, and they will release the
              payout when worker(s) submits a completed timesheets (refer to
              clause 8.2). If the timesheets are not approved within three days
              of the job's completion, the Job Provider agrees the payout will
              be calculated based on the worker's hours entered. Note, Temp does
              not have authority over payouts.
            </p>
            <p><strong>Workers must ensure:</strong></p>
            <p>
              <strong>7.5.1.&nbsp;</strong>That Temp has been supplied the
              correct bank account information. Payouts cannot be amended or
              revised and it is not the responsibility of Temp to track down
              missing funds.
            </p>
            <p>
              <strong>7.5.2. </strong>They pay tax on earnings made through the
              Temp platform. As workers operate as independent contractors, they
              are responsible for paying their own taxes.
            </p>

            <!--/Section 8. Timesheets</-->

            <p>
              <span style="font-size: 24pt"><strong>8. Timesheets</strong></span><br /><span
                style="font-size: 18pt"><strong>8.1. Timesheet entry</strong></span><br />Workers are obligated to
              record their hours accurately.
              This includes their start and finish times and their break times
              if applicable. If a worker fails to record their hours before the
              job completion date, then it is at the discretion of the Job
              provider to fill it out for them.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>8.2. Timesheet approval</strong></span><br />Workers will track
              their time worked for the Job Provider
              and submit a timesheet for approval via the Temp Platform, unless
              otherwise agreed in writing by Temp. The worker(s) must submit
              their hours, and the Job Provider must approve their timesheets
              weekly or at the completion of the job if shorter than one week.
              &nbsp;The Job provider shall verify and approve timesheets weekly
              on Fridays unless the job duration is less than 5 days, then it
              must be approved once the job is completed. Timesheets must be
              approved within the Timesheet approval period of 3 days. If the
              Job provider does not dispute or edit the workers hours within the
              Timesheet Approval Period, the Job Provider agrees to the payout
              calculated based on the worker's hours entered.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>8.3. Timesheet discrepancies</strong></span><br />Workers can
              complete additional hours with the Job
              providers approval. All additional hours should be recorded in the
              Timesheet on the Temp platform. If the Job provider agrees to the
              additional hours, the Job provider will be charged an additional
              fee for the extra hours. In the event the worker works less than
              the agreed upon hours, then the Job provider will be entitled to a
              refund (clause 7.4).
            </p>

            <!--/Section 9. Promotions</-->

            <p>
              <span style="font-size: 24pt"><strong>9. Promotions</strong></span><br />Temp is the
              &ldquo;<strong>Promoter</strong>&rdquo;
              responsible for these promotions.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>9.1 Disqualified Participants:</strong></span><br />The following
              individuals, referred to as "Disqualified
              Participants," are ineligible to participate in these promotions:
            </p>
            <p>
              <strong>9.1.1. </strong>All current Temp employees, all employees
              who work for associated marketing or advertising agencies, and
              their immediate families.
            </p>
            <p>
              <strong>9.1.2.</strong> All individuals under the age of 18 years
              if the prize involves air travel or any other element that is
              illegal to supply to a person under 18 years of age.
            </p>
            <p>
              <strong>9.1.3. </strong>All individuals who have won a prize from
              a Temp promotion within the last 30 days. If the previous prize
              was valued at over $1,000, the winner must wait 90 days before
              entering further Temp promotions again.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>9.2. Promotions &amp; Giveaways
                  Entry</strong></span><br /><strong>9.2.1. </strong>These rules, referred to as "the
              Rules," apply to all promotions and competitions by Temp, referred
              to as "Promotion," whether conducted online or in-person through
              any medium, including while completing a job or online through a
              connected device. The Rules are subject to change.
            </p>
            <p>
              <strong>9.2.2.</strong> If a specific Promotion has its own set of
              rules or terms, referred to as "Specific Rules," those Specific
              Rules will take precedence in case of any inconsistencies with the
              Rules.
            </p>
            <p>
              <strong>9.2.3.</strong> Unless otherwise specified in the Specific
              Rules, registration, entry, or voting is limited to one per
              person. If multiple registrations, entries, or votes are allowed,
              each must be submitted separately.
            </p>
            <p>
              <strong>9.2.4. </strong>By entering the Promotion, participants
              acknowledge that they have read and understood the Rules and the
              Specific Rules (if applicable) and have the necessary authority to
              enter the Promotion (such as permission from the bill payer or
              device owner).
            </p>
            <p>
              <strong>9.2.5.</strong> No purchase is necessary to participate or
              win, unless stated otherwise in the Specific Rules.
            </p>
            <p>
              <strong>9.2.6.</strong> The Promotion is open to New Zealand
              residents and those who have the right to work or operate as
              independent contractors. Disqualified Participants may not enter
              the Promotion.
            </p>
            <p>
              <strong>9.2.7. </strong>Temp reserves the right to exclude any
              individual from participating in the Promotion for reasonable
              grounds. Temp also reserves the right to refuse to award any prize
              to a winner if Temp, in its sole discretion, determines that the
              winner violated the Rules (including the Specific Rules), gained
              an unfair advantage, or won using fraudulent means.
            </p>
            <p>
              <strong>9.2.8.</strong> Temp reserves the right to refuse to award
              any prize to an entrant who Temp decides (in its sole discretion)
              has violated the Rules (including the Specific Rules), gained
              unfair advantage in participating in the Promotion or won using
              fraudulent means.
            </p>
            <p>
              <strong>9.2.9.</strong> By participating, entrants grant Temp
              exclusive permission to use their names, characters, photographs,
              videos, voices, and likeness in connection with the Promotion and
              for future promotional and marketing purposes. Entrants waive any
              claims to royalties, rights, or remuneration for such use.
            </p>
            <p>
              <strong>9.2.10.</strong> All personal information provided by
              participants must be valid and up-to-date. Temp may use this
              information for the Promotion and future promotional and marketing
              purposes in accordance with its Privacy Policy (available on the
              website www.temp.org.nz), unless otherwise directed by the
              participant at the time of entry.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>9.3. Winning the Prize</strong></span>
            </p>
            <p>
              <strong>9.3.1.</strong> Only the individual who entered the
              Promotion or competition can win the prize, referred to as the
              "Winner," unless otherwise specified by Temp in the Specific
              Rules.
            </p>
            <p>
              <strong>9.3.2. </strong>The Winner will be determined according to
              the Rules or the Specific Rules, or if not specified, by Temp
              acting as the judge, referred to as the "Judge."
            </p>
            <p>
              <strong>9.3.3.</strong> The Judge's determination of the Winner
              will be final, and no correspondence will be entered into.
            </p>
            <p>
              <strong>9.3.4. </strong>The Winner will be notified by either
              email, phone (voice or text), mail, or in-person, and must be
              available for all required publicity by Temp. If the Winner cannot
              be reached after three attempts by phone or if mail is returned,
              Temp will select another winner. If the prize is not collected
              within two months of being announced, it will be forfeited. Note
              that three attempts will be made to contact the Winner.
            </p>
            <p>
              <strong>9.3.5.</strong> Where the Winner is required to claim the
              prize in person, they must provide government issued photo
              identification such as a driver's license, or passport.
            </p>
            <p>
              <strong>9.3.6.</strong> The recipient of the Prize assumes full
              responsibility and bears all risks associated with it, and
              indemnifies Temp against any claims arising from accidents,
              injuries, property damage, or loss of life that may occur in
              connection with the Prize. The Winner is also responsible for all
              costs, including insurance, taxes, and other expenses that may be
              associated with the Prize. If there are any terms and conditions
              or limitations associated with the Prize, the Winner accepts them
              as a condition of receiving the Prize.
            </p>

            <!--/Section 10. Exclusions of Liability</-->

            <p>
              <span style="font-size: 24pt"><strong>10. Exclusions of Liability</strong></span><br />Neither Party shall
              be held liable, in contract, tort,
              under statute or otherwise, for any consequential or indirect
              losses sustained by the other Party, including but not limited to
              loss of investment, loss of contract, loss of production, loss of
              profits, loss of time, or loss of use.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>10.1 Job provider</strong></span>
            </p>
            <p>
              <strong>10.1.1&nbsp;</strong>The Job provider acknowledges that
              Temp does not hold motor vehicle or any other relevant insurance
              for vehicles that are registered or required at law to be
              registered and which are not owned by Temp. Temp shall not be held
              liable, and the Job provider shall indemnify Temp and workers on
              the Temp platform against any loss (including liability under any
              indemnity or claims by third parties) related to a Job Brief, to
              the extent that the loss (i) is arising out of or in connection
              with driving or operating any motor or commercial vehicle(s) by
              the worker(s) in the course of their Work; or (ii) would
              ordinarily and commonly be covered by a motor vehicle insurance
              policy or any such equivalent or relevant insurances. If a worker
              chooses to use their personal Motor vehicle for a job brief this
              is at the Workers discretion, and they will assume full liability.
            </p>
            <p>
              <strong>10.1.2.</strong> The Job provider acknowledges that the
              worker will be working under the Job provider's supervision,
              control, direction, and instruction at the Job Site. Due to this
              reason, the Job provider agrees that Temp will not be liable to
              the Job provider for, and the Job provider will indemnify Temp
              against, any loss of whatsoever nature or kind, however caused by
              one or more of the workers (including by their negligence) whilst
              they are working for the Job provider under a Job Brief.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>10.2 Workers</strong></span>
            </p>
            <p>
              <strong>10.2.1</strong> The worker acknowledges that they act as
              an independent contractor and are responsible for paying their own
              income tax. It is not the responsibility of Temp or the Job
              provider to pay any income tax, ACC or superannuation benefit. The
              worker also acknowledges that Temp will not be responsible for any
              Tax fines or penalties incurred due to undeclared income or unpaid
              tax.
            </p>
            <p>
              <strong>10.2.2.</strong> Temp and the Job provider will not be
              accountable for any damage to the worker&rsquo;s personal property
              when carrying out a Job. It is the choice of the worker whether to
              use a personal motor vehicle or other personal equipment that may
              be needed to complete the job. This should be outlined in the job
              brief prior to the worker accepting the job.
            </p>
            <p>
              <strong>10.2.3.</strong> The worker acknowledges that they will be
              working under the Job provider's supervision, control, direction,
              and instruction at the Work Site. For this reason, the worker
              agrees that Temp will not be liable to the worker for, and the
              worker will indemnify Temp against, any loss of whatsoever nature
              or kind, however caused by the worker (including by their
              negligence) whilst they are working for the Job provider under a
              Job Brief.
            </p>

            <!--/Section 11. Intellectual Property</-->

            <p>
              <span style="font-size: 24pt"><strong>11. Intellectual Property</strong></span>
            </p>
            <p>
              <strong>11.1</strong> Temp agrees that all Intellectual property
              and other information provided by the Job provider to Temp remains
              the property of the Job provider.
            </p>
            <p>
              <strong>11.2.</strong> The Job provider acknowledges and agrees
              that Temp continues to own all of its own Intellectual Property
              existing at the date of this Agreement or coming into existence
              during the Term of this Agreement (including any improvement to
              such Intellectual Property developed during the Term of the
              Agreement). Temp grants the Job provider a limited, revocable
              right to access and use the Temp Platform for the purpose and to
              the extent as allowed under this Agreement during the Term.
            </p>

            <!--/Section 12. Notices</-->

            <p>
              <span style="font-size: 24pt"><strong>12. Notices</strong></span>
            </p>
            <p>
              <strong>12.1. </strong>All mention of notices under this agreement
              are to be given in writing.
            </p>
            <p>
              <strong>12.2. </strong>Notices must be sent electronically in the
              form of email to the contact email set out in this clause.<strong><br /></strong>
            </p>
            <p dir="ltr">
              (Email):<span style="color: rgb(0, 176, 116)">
                <a style="color: rgb(0, 176, 116)"
                  href="mailto:support@temp.org.nz">support@temp.org.nz</a>&nbsp;</span>
            </p>
            <p dir="ltr">
              Job provider and Workers: (Address &amp; Email) as registered
              through the Temp platform or updated through written notice.
            </p>
            <p>
              <strong>12.3. </strong>The Job provider and Worker are responsible
              for providing Temp with their current email address. In the event
              that the last email address provided to Temp is invalid, or for
              any reason is not capable of delivery to Temp, Temp will not
              recognize that notice has been given.
            </p>
            <p>
              <strong>12.4.</strong> The Job provider and worker given notice
              under this Agreement by Temp will constitute effective notice by
              Temp dispatching of an email containing such notice.
            </p>

            <!--/Section 13.Termination </-->

            <p>
              <span style="font-size: 24pt"><strong>13. Termination</strong></span><br /><span
                style="font-size: 18pt"><strong>13.1 Immediate termination</strong></span><br />The Job Provider and the
              Worker may immediately terminate
              this Agreement by written notice to the other if any of the
              following occurs:
            </p>
            <ol>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  A termination as a result of a Party breaching any term of the
                  Agreement and failing to remedy the breach within 7 days of
                  being requested to do so, or if the breach is irreparable.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  If a Job provider becomes Insolvent.&nbsp;
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  If a Job provider suspends or ceases, or threatens to suspend
                  or cease, a substantial part of its business operations.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  Termination of this agreement does not constitute a
                  cancelation of subscription plans nor an in progress job
                  brief.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  If a worker suspends or ceases, or threatens to suspend or
                  cease, a substantial part of their services.
                </p>
              </li>
              <li dir="ltr" aria-level="1">
                <p dir="ltr" role="presentation">
                  If a Job provider or worker engages in fraud, dishonesty, or
                  any other serious misconduct, immediate termination may be
                  initiated.
                </p>
              </li>
            </ol>
            <p>
              <strong>13.2. </strong>Additionally, Temp may terminate the
              Agreement by providing written notice to the Job provider if it
              reasonably believes that the Work Site is or has become unsafe for
              any reason.
            </p>
            <p>
              <span style="font-size: 18pt"><strong>13.3. Termination without Cause</strong></span><br />Either Party
              may terminate this Agreement at any time but
              will need to provide reasons and must give 14 days written notice.
            </p>

            <!--/Section 14. Force Majeure</-->

            <p>
              <span style="font-size: 24pt"><strong>14. Force Majeure</strong></span>
            </p>
            <p>
              <strong>14.1.&nbsp;</strong>If a Party is prevented from or
              delayed in performing an obligation under this Agreement
              (including under any Job Brief, other than an obligation to pay
              invoices pursuant to Clause 7.3.2) by a Force Majeure Event, then
              the obligation is suspended during, but for no longer than, the
              period the Force Majeure Event continues and such further period
              as reasonable in the circumstances. Neither Party shall be liable
              for any such suspension, delay or disruption to the performance of
              any obligations as a result of such Force Majeure Event.
            </p>
            <p>
              <strong>14.2. </strong>The Party that is prevented from or delayed
              in performing the obligation must as soon as reasonably possible,
              notify the other Party of its inability to perform the obligation
              due to the Force Majeure Event. This can be done by using the
              report function either within the job page for the Worker or in
              the applicant list for the Job provider.
            </p>

            <!--/Section 15 Governing Law</-->

            <p>
              <span style="font-size: 24pt"><strong>15. Governing Law</strong></span>
            </p>
            <p>
              This Agreement will be governed by the laws of New Zealand and the
              Parties irrevocably submit to the non-exclusive jurisdiction of
              the courts in that jurisdiction.
            </p>

            <!--/Section 16 Right to work</-->

            <p>
              <span style="font-size: 24pt"><strong>16. Right to work</strong></span>
            </p>
            <p>
              All workers on the temp platform must hold New Zealand residency
              or have the necessary Visa or associated documentation to perform
              work lawfully. A list of acceptable visas can be found at
              www.immigration.govt.nz. All workers must comply with the
              Immigration Act 2009 and hold the applicable documentation to
              perform work operating as an independent contractor. Failure to
              prove or provide such documentation will result in a ban from the
              Temp platform.
            </p>

            <!--/Section 17 Temps Geofence</-->

            <p>
              <span style="font-size: 24pt"><strong>17. Temp&rsquo;s Geofence</strong></span>
            </p>
            <p>
              Temp uses geofencing technology to ensure that our website is only
              accessible to users who are within specific geographic boundaries.
              Geofencing is a location-based service that allows us to define
              virtual geographic boundaries, enabling us to restrict access to
              our website to users who are physically located within those
              boundaries.&nbsp;
            </p>
            <p>
              By using Temp, you consent to our use of geofencing technology to
              restrict access to our website based on your location.&nbsp;
            </p>
            <p>
              We only collect and use location data to ensure that you are
              within the designated geographic boundaries to access our website.
              We do not use this data for any other purposes or share it with
              any third parties.&nbsp;
            </p>
            <p>
              If you have any questions or concerns about our use of geofencing
              technology, please contact our customer support team. By using
              Temp, you agree to our use of geofencing technology as described
              in these terms and conditions."
            </p>

            <!--/Section 18 Changes to this agreement</-->

            <p>
              <span style="font-size: 24pt"><strong>18. Changes to this agreement</strong></span>
            </p>
            <p>
              Temp reserves the right to modify the terms of this Agreement or
              its policies relating to the Temp platform at any time, effective
              upon the posting of an updated version of this Agreement on the
              Temp website.
            </p>
            <p>
              By continuing to use the Temp platform or receiving Services from
              Temp (including continuing with an existing or outstanding Job),
              the Job provider and worker confirm they agree to be bound by this
              Agreement and any amended terms of this Agreement.
            </p>

          </tab>
          <tab heading="PRIVACY POLICY" id="privacy-policy-tab">
            <span class="updated-date py-6">Last updated 24/03/2023</span>
            <p dir="ltr"><span style="font-size: 12pt;">This privacy notice for Temp Limited
                ('<strong>Company</strong>', '<strong>we</strong>', '<strong>us</strong>', <strong>or</strong>
                '<strong>our</strong>'), describes how and why we might collect, store, use, and/or share ('process')
                your information when you use our services ('Services'), such as when you:</span></p>
            <ul>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Visit our website at <span
                      style="color: rgb(0, 176, 116);"><a style="color: rgb(0, 176, 116);"
                        href="https://www.temp.org.nz">https://www.temp.org.nz</a></span>, or any website of ours that
                    links to this privacy notice</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Engage with us in other related ways,
                    including any sales, marketing, or events</span></p>
              </li>
            </ul>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>Collecting your personal information</strong></span></p>
            <p dir="ltr"><span style="font-size: 12pt;">We collect personal information that we need in order to deliver
                our services to you. We collect personal information from you when you use our services.</span></p>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>Information we collect from you</strong></span></p>
            <ul>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">First name</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Last name&nbsp;</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Address</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Phone number</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Email&nbsp;</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Billing information</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Billing address</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Bank account number</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Company name</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Company address</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Profile photo (optional)</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Location information</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Website activity - To understand how you
                    use our website and improve our services.</span></p>
              </li>
              <ul>
                <li dir="ltr" style="font-size: 12pt;" aria-level="2">
                  <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Page visits</span></p>
                </li>
                <li dir="ltr" style="font-size: 12pt;" aria-level="2">
                  <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Time spent on pages</span></p>
                </li>
                <li dir="ltr" style="font-size: 12pt;" aria-level="2">
                  <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Movements on pages</span></p>
                </li>
                <li dir="ltr" style="font-size: 12pt;" aria-level="2">
                  <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Analytics on jobs</span></p>
                </li>
              </ul>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Transaction history</span></p>
              </li>
            </ul>
            <p dir="ltr"><span style="font-size: 12pt;">Any other information you choose to provide us.</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">Collectively, this is known as your &ldquo;personal
                information".</span></p>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>Using your personal information</strong></span></p>
            <p dir="ltr"><span style="font-size: 12pt;">In order to deliver our services, we use your personal
                information in the ways set out below. If we need to use information in a way not outlined below, we
                will only do so if required or permitted by law or with your authorization.</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">We use your personal information to:</span></p>
            <ul>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Provide you with Temp services</span>
                </p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Setup your account</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Contact you to provide support</span>
                </p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Invoice you, or receive payment from
                    you, for any of our services that require payment.</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Issue payouts.</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Conduct market research surveys if you
                    opt-in.</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Target or customize our marketing and
                    advertising.</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Tell you about our new products,
                    services, promotions and campaigns. For example, if you are opted-in to email marketing
                    communications we may email you to let you know about new content you may be interested in.</span>
                </p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Provide you with information about the
                    operation and security of our websites.</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Contact you to advise you of changes to
                    our terms and conditions, services or new products or promotions.</span></p>
              </li>
            </ul>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>Email communications</strong></span></p>
            <p dir="ltr"><span style="font-size: 12pt;">All Temp users have the choice to opt in to receive email
                marketing communications, including surveys. For all our marketing emails, you can choose to remove
                yourself from receiving these communications by following the "unsubscribe" prompt at the bottom of each
                email.</span></p>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>SMS communications</strong></span></p>
            <p dir="ltr"><span style="font-size: 12pt;">All Temp users will be sent transactional or marketing SMS
                messages. Transactional SMS messages act as notifications about jobs, these are triggered by events on
                the Temp platform. If you wish to opt-out of SMS notifications, you can untick the SMS box in your
                settings. If you wish to not receive marketing SMS&rsquo;s you can reply with opt out.</span></p>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>Sharing your personal information</strong></span></p>
            <p dir="ltr"><span style="font-size: 12pt;">We do not sell or rent your personal information to any third
                party. However, we do share your personal information with trusted third parties that work on our behalf
                to deliver our services to you (such as for computing and storage purposes, for email correspondence and
                marketing purposes).</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">Third parties, some of which are based overseas, which we share
                your personal information with include:</span></p>
            <ul>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Freshworks, for marketing automation and
                    email marketing.</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Amazon Web Services (AWS), our cloud
                    hosting service provider.</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Stripe, our payment gateway
                    provider.</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Burst SMS, our SMS sender</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">YouTube, Google Analytics and Google Ad
                    Manager, for targeted advertising.</span></p>
              </li>
              <li dir="ltr" style="font-size: 12pt;" aria-level="1">
                <p dir="ltr" role="presentation"><span style="font-size: 12pt;">Social Media Platforms (Linkedin,
                    Facebook and Instagram), for targeted advertising.</span></p>
              </li>
            </ul>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>Hotjar</strong></span></p>
            <p dir="ltr"><span style="font-size: 12pt;">We use Hotjar in order to better understand our users' needs and
                to optimize this service and experience. Hotjar is a technology service that helps us better understand
                our users' experience (e.g. how much time they spend on which pages, which links they choose to click,
                what users do and don't like, etc.) and this enables us to build and maintain our service with user
                feedback. Hotjar uses cookies and other technologies to collect data on our users' behavior and their
                devices.This includes a device's IP address (processed during your session and stored in a de-identified
                form), device screen size, device type (unique device identifiers), browser information, geographic
                location (country only), and the preferred language used to display our website. Hotjar stores this
                information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any
                of the data collected on our behalf.</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">We do not authorize third parties to use or disclose your
                personal information except for the purpose of providing the service we request from them. All third
                parties, which we disclose your personal information to, are subject to obligations under the New
                Zealand Privacy Act 2020 to protect your information from unauthorized use or disclosure.</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">For further details, please see the 'about Hotjar' section of
                Hotjar's support site.</span></p>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>Retention of data</strong></span></p>
            <p dir="ltr"><span style="font-size: 12pt;">We retain your personal information for as long as we need it to
                provide our services to you and to comply with legal obligations. We take all reasonable steps to
                protect your personal information against unauthorized access, disclosure, or other misuse. For example,
                we have an information and communications technology policy in place, and access to our systems is
                controlled and audited.</span></p>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>Security</strong></span></p>
            <p dir="ltr"><span style="font-size: 12pt;">We take all reasonable steps to ensure the personal information
                we collect is protected against loss, unauthorized access and disclosure or any other misuse. We have an
                information and communications technology policy in place to ensure staff manage and customer data.
                Access to our systems and platforms is controlled, through role-based access, and all access is logged
                and audited.</span></p>
            <p dir="ltr"><span style="font-size: 18pt;"><strong>Cookies</strong></span></p>
            <p dir="ltr"><span style="font-size: 12pt;">We use cookies and web beacons to help us understand how
                visitors engage with our website and services. These technologies allow us to:</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">Improve our website (for example, identify and promote popular
                content); and</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">Provide you with more targeted information where
                appropriate.</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">Temporary cookies: We use temporary (per-session) cookies to
                "remember" that a Temp user has logged in. This means you do not have to enter your email address and
                password more than once during a visit.</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">Please note: Our temporary cookies must be accepted to login and
                access www.temp.org.nz. If your browser is set to notify you when you receive a cookie, you will be
                prompted to accept the cookie. Please select "Yes".</span></p>
            <p dir="ltr"><span style="font-size: 12pt;">Our temporary cookies will disappear when you logout of your
                account. For any additional information about privacy please email <span
                  style="color: rgb(0, 176, 116);"><a style="color: rgb(0, 176, 116);"
                    href="mailto:support@temp.org.nz">support@temp.org.nz</a></span></span></p>
            <p dir="ltr">&nbsp;</p>
          </tab>
          <tab heading="PAYMENTS" id="payments-tab" [active]="true">
            <span class="updated-date py-6">Last updated 24/03/2023</span>
            <h3 style="text-align: center;"><strong>Payments policy.</strong></h3>
            <p><span style="font-size: 18pt;"><strong>Tax</strong></span><br>All workers on the Temp platform act as
              independent contractors which means they are required by law to pay their own taxes. It is not the
              responsibility of Temp nor the businesses that use Temp&rsquo;s services to pay taxes, nor is the worker
              entitled to regular employment benefits, as they are an independent contractor.&nbsp;</p>
            <p><strong><span style="font-size: 18pt;">Payouts</span></strong><br>Payouts are initiated by the Job
              Provider weekly or at the end of the job period. Payouts can take up to 3-7 business days to be received
              into the workers bank account.</p>
            <p style="text-align: center;"><span style="font-size: 18pt;"><strong>Correction Charges &amp;
                  Refunds</strong></span></p>
            <p><strong><span style="font-size: 18pt;">Additional hours</span></strong><br>If a Worker works more than
              the advertised hours, then a correction charge will be issued that is proportional to the additional time
              worked. The correction charge will be calculated by multiplying the hourly rate by the additional time
              worked plus Temp&rsquo;s service fee. If a correction charge is issued it will be displayed in the payment
              popup after the Job Provider clicks the pay now button on the &ldquo;Job&rsquo;s timesheet&rdquo;.</p>
            <p><span style="font-size: 18pt;"><strong>If a Worker works less than the advertised
                  hours.</strong></span><br>If a Worker works less than the advertised hours. A refund will be issued
              proportional to the time not worked. The refund will be calculated by multiplying the hourly rate by the
              time not worked plus Temps service fee. If a refund is issued it will be displayed in the payment popup on
              the final payout of the job after the Job Provider clicks the pay now button on the &ldquo;Jobs
              timesheet&rdquo;.</p>
            <p><strong><span style="font-size: 18pt;">If a Worker fails to turn up.</span></strong><br>If your Worker
              does not turn up to the job. The Job Provider must report them in the applicant&rsquo;s page and is
              required to set the total hours to &ldquo;Zero&rdquo; in the timesheet by setting the start and finish
              time to 00:00. This will initiate a refund to the Job Provider. Note, Temp does not charge its service fee
              on hours not worked.</p>
            <p><strong><span style="font-size: 18pt;">Payment processing fees.</span></strong><br>Payment processing
              fees are non-refundable. The payment processing fee is not charged by Temp and is enforced by our payment
              processor. So, if a worker fails to turn up and you are entitled to a refund in accordance with our Terms
              and Conditions, this does not include the 2.7% &amp; $30 &cent; payment processing fee. Please note that
              this is different to Temp&rsquo;s service fee.</p>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>