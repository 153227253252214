import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  constructor() { }
  
  ngOnInit(): void {
   /* let iframe = document.getElementById('iframe') as HTMLIFrameElement;
    console.log(iframe.contentWindow.document.documentElement.scrollHeight);
    document.getElementById("iframe").style.height = document.getElementById("iframe").scrollHeight + 'px'; */
  }


}
