import { Directive, HostListener} from '@angular/core';

@Directive({
    selector: '[NumberOnly]'
})

export class NumberOnlyDirective{

    constructor(){    }

    @HostListener('keydown',['$event'])onKeyDown(event: KeyboardEvent){
        return ((/[\d\s]/).test(event.key) || (event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 8 || event.keyCode == 46));
    }

    @HostListener('paste',['$event'])onPaste(event){
        return ((/[\d\s]/).test(event.clipboardData.getData("text/plain")));
    }
}
@Directive({
    selector: '[NumberSpecial]'
})

export class NumberSpecialDirective {

    constructor(){    }

    @HostListener('keydown',['$event'])onKeyDown(event: KeyboardEvent){
        return (((/^[\W\d]/).test(event.key)) || (event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 8 || event.keyCode == 46));
    }

    @HostListener('paste',['$event'])onPaste(event){
        return ((/^[\W\d]/).test(event.clipboardData.getData("text/plain")));
    }
}

@Directive({
    selector: '[AlphaNumaric]'
})

export class AlphaNumaricDirective {

    constructor(){    }

    @HostListener('keydown',['$event'])onKeyDown(event: KeyboardEvent){
        return ((/[\w\s]/).test(event.key));
    }

    @HostListener('paste',['$event'])onPaste(event){
        return ((/[\w\s]/).test(event.clipboardData.getData("text/plain")));
    }
}

@Directive({
    selector: '[Alphabets]'
})

export class AlphabetsDirective {

    constructor(){    }

    @HostListener('keydown',['$event'])onKeyDown(event: KeyboardEvent){
        return ((/[A-Za-z\s]/).test(event.key));
    }

    @HostListener('paste',['$event'])onPaste(event){
        return ((/[A-Za-z\s]/).test(event.clipboardData.getData("text/plain")));
    }
}

@Directive({
    selector: '[AlphaSpecial]'
})

export class AlphaSpecialDirective {

    constructor(){    }

    @HostListener('keydown',['$event'])onKeyDown(event: KeyboardEvent){
        return ((/[\D\s]/).test(event.key));
    }

    @HostListener('paste',['$event'])onPaste(event){
        return ((/[\D\s]/).test(event.clipboardData.getData("text/plain")));
    }
}

@Directive({
    selector: '[telephone]'
})

export class TelephoneDirective {

    constructor(){    }

    @HostListener('keydown',['$event'])onKeyDown(event: any){
        let number = event.target.value;
        let key = event.key;
        if (key !== "Delete") {
            if (key !== "Backspace") {
            var fourthCharacter = number.charAt(3);
            var seventhCharacter = number.charAt(7);
            if (number.length == 4) {
                var fourth =
                fourthCharacter != "-" ? "-" + number.charAt(3) : fourthCharacter;
                var remainsChar = number.substring(0, 3);
                event.target.value = remainsChar + fourth;
            }
            if (number.length == 8) {
                var eight =
                seventhCharacter != "-" ? "-" + number.charAt(7) : seventhCharacter;
                var remainsEigntChar = number.substring(0, 7);
                event.target.value = remainsEigntChar + eight;
            }
            }
        }
        return true;
    }

    @HostListener('paste',['$event'])onPaste(event){
        return false;
    }
}

@Directive({
    selector: '[hours]'
})

export class hoursDirective {

    constructor(){    }

    @HostListener('keydown',['$event'])onKeyDown(event: any){
        if (event.key != "Backspace" && event.key != "Delete") {
            var number = event.target.value;
            if (number.length == 2) {
              var third = number.charAt(2) != ":" ? ":" + number.charAt(2) : number.charAt(2);
              event.target.value = number.substring(0, 2) + third;
            }
            if (number.length > 3 && !event.target.value.includes(":")) {
                event.target.value = event.target.value.substr(0, 2) + ":" + event.target.value.substr(2);
            }
        }
        return true;
    }

    @HostListener('paste',['$event'])onPaste(event){
        return false;
    }
}
