import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './_shared-components/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NumberOnlyDirective } from './_directives/formvalidation.directive';
import { CustomAlertComponent } from './_shared-components/alert/alert.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { LoaderComponent } from './_shared-components/loader/loader.component';
import { HeaderComponent } from './_shared-components/header/header.component';
import { FooterComponent } from './_shared-components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderInterceptor } from './_services/header-interceptor';
import { FaqComponent } from './_shared-components/faq/faq.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { RatingModule } from 'ngx-bootstrap/rating';
import { ContactUsComponent } from './_shared-components/contact-us/contact-us.component';
import { AboutUsComponent } from './_shared-components/about-us/about-us.component';
import { CareersComponent } from './_shared-components/careers/careers.component';
import { BlogComponent } from './_shared-components/blog/blog.component';
import { PrivacyPolicyComponent } from './_shared-components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './_shared-components/terms-conditions/terms-conditions.component';
import { PaymentsComponent } from './_shared-components/payments/payments.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { HelpComponent } from './_shared-components/help/help.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { QuillModule } from 'ngx-quill';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SiteUnavailableComponent } from './site-unavailable/site-unavailable.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent, ResetPasswordComponent, 
    NumberOnlyDirective, CustomAlertComponent, LoaderComponent, HeaderComponent, FooterComponent, FaqComponent, ContactUsComponent, AboutUsComponent, CareersComponent, BlogComponent, PrivacyPolicyComponent, TermsConditionsComponent, PaymentsComponent, HelpComponent, SiteUnavailableComponent, 
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ClipboardModule,
    AlertModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    ButtonsModule.forRoot(),
    RatingModule.forRoot(),
    CarouselModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    NgxSliderModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    QuillModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
