<div class="bg-white pt-30 pb-13 pb-xxl-32 blog-page mx-lg-0">
    <div class="container px-5 px-lg-0 w-90 mw-100">
        <div class="row justify-content-center blog-page-wrap ml-xl-30 ml-xxxl-35 mr-xxxl-32 mr-xl-20 mx-auto">
            <div class="px-0 pl-lg-6 pr-lg-4 col-12 col-lg-8 right-column">
                <h2>We're launched!</h2>
                <span class="blog-updated mb-9 font-size-20px">10/07/2023</span>
                <!-- <p class="mb-9 font-size-20px">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur mattis tellus massa, quis convallis est venenatis nec. Nullam vulputate erat et sem imperdiet vulputate. Cras at ipsum sit amet lectus fringilla eleifend. Aliquam accumsan ac odio eu hendrerit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed molestie massa sapien, a tristique nibh imperdiet ut. Morbi sollicitudin viverra enim, in feugiat metus maximus ut. Nunc pulvinar aliquet neque. Vivamus eu ligula sed diam pretium pellentesque id in quam. Donec commodo, quam sed dictum molestie, libero massa scelerisque augue, vitae dapibus dui turpis vel purus. Integer sed commodo sem. Phasellus sed dolor nibh.
                </p>
                <p class="mb-9 font-size-20px">Nulla tristique justo massa, at cursus ligula blandit nec. Pellentesque ut neque sagittis, euismod purus sit amet, elementum libero. Aliquam a orci tortor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi sagittis imperdiet elit ac mattis. Curabitur vel consequat enim. Nulla turpis erat, lobortis sit amet ultrices et, faucibus a quam. In feugiat diam sapien, non pellentesque leo egestas ullamcorper. Nunc euismod euismod lectus at lobortis. Quisque tincidunt finibus arcu eu dapibus. Nulla facilisi. Vivamus nec vulputate sapien. Mauris sit amet volutpat ipsum. Nunc sit amet lectus dui.
                </p>
                <p class="mb-9 font-size-20px">Pellentesque nulla eros, bibendum eu porta et, sagittis a sapien. Mauris erat justo, consectetur pretium tellus dictum, dapibus tincidunt lacus. Vivamus vehicula gravida libero, sed lacinia dolor aliquet eu. Integer fringilla suscipit sem. Maecenas volutpat nibh a lorem vehicula dictum. Sed pellentesque, mauris non rutrum tincidunt, mi risus
                </p> -->
                <p dir="ltr">Temp is finally here and we are thrilled. Our innovative new platform is designed to help businesses of all sizes connect with skilled and able workers quickly and efficiently.</p>
                <p dir="ltr">Temp offers a streamlined, user-friendly experience for businesses seeking to fill their temporary workforce needs. Our platform uses advanced matching algorithms to match workers with the shifts that suit their schedule kind of like tinder for jobs. This gives businesses access to an untapped workforce and allows workers more flexible work opportunities.</p>
                <p dir="ltr">"We know that finding the right workers can be a time-consuming and frustrating process. Our platform takes the hassle out of hiring, allowing businesses to focus on what they do best&rdquo; Said Jared Crowle the founder CEO of Temp. We do this through our unique rating system, it's meant to be more advanced than your standard 5 star, and give each business a realistic view of their applicant.</p>
                <p dir="ltr">Temp also offers a range of features designed to help businesses manage their workforce more effectively. These include time tracking and scheduling, and performance analytics. This makes it easy for businesses to keep track of their workers timesheets so they can spend less time on admin and payroll.</p>
                <p dir="ltr">In addition to its benefits for businesses, Temp also offers opportunities for workers seeking flexible and meaningful work. By signing up to Temp, workers gain access to a range of job opportunities and benefits, including competitive pay rates and job flexibility.</p>
                <p dir="ltr">"We believe that everyone deserves the opportunity to find flexible and fulfilling work that meets their needs, Temp is designed to help workers achieve their short term financial goals as well as build a positive career profile that is a real life testament to their work ethic." said Jared Crowle, the founder and CEO of Temp.</p>
                <p dir="ltr">Temp is excited to launch <a href="http://www.temp.org.nz">www.temp.org.nz</a> and looks forward to helping businesses and workers achieve their goals.</p>
                <p dir="ltr">Disclaimer</p>
                <p dir="ltr">Temp is not a recruitment agency, we are a disruptive software startup that's reinventing temp work.</p>
                <p dir="ltr">&nbsp;</p>
                <!-- <div class="show-hide">
                    <h2 class="mt-22">Where launched</h2>
                    <span class="blog-updated mb-9 font-size-20px">28/08/2021</span>
                    <div class="blog-image mb-9">
                        <img class="w-100" src="../../../assets/image/png/blog-img1.png"/>
                    </div>
                    <p class="mb-9 font-size-20px">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur mattis tellus massa, quis convallis est venenatis nec. Nullam vulputate erat et sem imperdiet vulputate. Cras at ipsum sit amet lectus fringilla eleifend. Aliquam accumsan ac odio eu hendrerit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed molestie massa sapien, a tristique nibh imperdiet ut. Morbi sollicitudin viverra enim, in feugiat metus maximus ut. Nunc pulvinar aliquet neque. Vivamus eu ligula sed diam pretium pellentesque id in quam. Donec commodo, quam sed dictum molestie, libero massa scelerisque augue, vitae dapibus dui turpis vel purus. Integer sed commodo sem. Phasellus sed dolor nibh.
                    </p>
                    <p class="mb-9 font-size-20px">Nulla tristique justo massa, at cursus ligula blandit nec. Pellentesque ut neque sagittis, euismod purus sit amet, elementum libero. Aliquam a orci tortor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi sagittis imperdiet elit ac mattis. Curabitur vel consequat enim. Nulla turpis erat, lobortis sit amet ultrices et, faucibus a quam. In feugiat diam sapien, non pellentesque leo egestas ullamcorper. Nunc euismod euismod lectus at lobortis. Quisque tincidunt finibus arcu eu dapibus. Nulla facilisi. Vivamus nec vulputate sapien. Mauris sit amet volutpat ipsum. Nunc sit amet lectus dui.
                    </p>
                    <p class="mb-9 font-size-20px">Pellentesque nulla eros, bibendum eu porta et, sagittis a sapien. Mauris erat justo, consectetur pretium tellus dictum, dapibus tincidunt lacus. Vivamus vehicula gravida libero, sed lacinia dolor aliquet eu. Integer fringilla suscipit sem. Maecenas volutpat nibh a lorem vehicula dictum. Sed pellentesque, mauris non rutrum tincidunt, mi risus
                    </p>
                </div> -->
                
                <span class="blog view-more font-size-13px font-weight-bold text-green text-center w-100">VIEW MORE</span>

            </div>
            <div class="px-16 pl-lg-20 pr-lg-6 pt-13 pt-lg-10 col-12 col-lg-4 left-cloumn">
                <h5>Sign up to our blog</h5>
                <span class="sign-up-text mb-9 mt-3">Get the latest updates on new posts and platform improvements</span>
                <div class="sign-up-form">
                    <input type="text" class="form-control h-px-48" placeholder="Email">
                    <button type="submit" class="btn btn-outline-success w-50 mt-7 btn-lg px-8 py-7 text-uppercase">Subscribe</button>
                </div>
                <div class="blog-recent-posts mt-11 border-bottom">
                    <h6 class="font-size-25px mb-9">Recent Posts</h6>
                    <span class="post-list border-top py-4 w-100 position-relative">
                        <span class="post-heading font-weight-semibold w-100 pb-4">We're launched</span>
                        <span class="post-date font-size-13px">10/07/2023</span>
                        <span class="post-icon position-absolute"></span>
                    </span>
                    <!-- <span class="post-list border-top py-4 w-100 position-relative">
                        <span class="post-heading font-weight-semibold w-100 pb-5">Where launched</span>
                        <span class="post-date font-size-13px">20/08/2021</span>
                        <span class="post-icon position-absolute"></span>
                    </span> -->
                </div>
            </div>
        </div>
    </div>
</div>