import { Component, OnInit} from '@angular/core';
import { AppService } from './app.service';
import { GlobalService } from './_services/global.service';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  hasAccess: boolean = false;
  user:any = {};
  
  constructor(public appService: AppService){
  }

  ngOnInit(): void {
    this.user = {};
  }

  signIn(){
    this.appService.showApplication(this.user).subscribe((res:any)=>{
    })
  }
  
}
