import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of,throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { GlobalService } from './global.service';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  excludeURL = ['https://oauth.nzpost.co.nz/as/token.oauth2','https://api.nzpost.co.nz/addresschecker/1.0/suggest']

  constructor(private globalService: GlobalService, private router: Router, private storage:StorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.storage.getItems("token");
    if (token != null && !this.excludeURL.includes(request.url))
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    return next.handle(request).pipe(
      catchError((err: any) => {
        this.globalService.hideLoader();
        if (!err.ok) {
          if(err.error.path.includes("pay_to_workers") && err.error.status == 417)
              return throwError(err);
          if(!(typeof err.error === "undefined") && err.error.path == "/token/generate-token"){
            this.globalService.addAlert("danger", "Invalid Username or Password");
          }
          else if (err.status == 401 && this.excludeURL.includes(request.url)) {
            this.globalService.getAddressToken();
            this.globalService.addAlert("danger", "Servers are unreachable. Try again later.");
          }else if(err.status == 401){
            this.globalService.addAlert("danger", "Session Expired");
            this.router.navigate(['home']);
            localStorage.clear();
          }else if(err.status == 409 || (typeof err.error.message !== "undefined" && err.error.message != "")){
            this.globalService.addAlert("danger",err.error.message);
          }else if(err.status == 500){
            if (err.error.message == "")
            this.globalService.addAlert("danger", "Servers are unreachable. Try again later.");
      else
        this.globalService.addAlert('danger', err.error.message);
          }
          else {
            this.globalService.addAlert("danger", "Servers are unreachable. Try again later.");
          }
        }
        else {
          this.globalService.addAlert("danger", "Servers are unreachable. Try again later.");
        }
        return of(err);
      })
    );
  }
}