import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})

export class StorageService {

    setItems(key, value) {
        localStorage.setItem(key, btoa(JSON.stringify(value)));
    }

    getItems(key) {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(atob(value)) : value;
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    clearAllItems() {
        localStorage.clear();
    }

}