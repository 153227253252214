import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalService } from 'src/app/_services/global.service';
import { StorageService } from 'src/app/_services/storage.service';

// This lets me use jquery
declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  @ViewChild('removetemplte') infoModal: TemplateRef<any>;
  @ViewChild('formDirective', { static: false }) formDirective: NgForm;
  modalRef: BsModalRef;
  contactUsForm: FormGroup;

  contactSubjects = ["Test 1", "Test 2"];

  constructor(private modalService: BsModalService, private storageService: StorageService, private route: Router, private globalService: GlobalService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      $(".selectpicker").selectpicker("refresh");
    }, 100);
    this.initializeForm();
  }

  initializeForm() {
    this.contactUsForm = new FormGroup({
      'name': new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      'email': new FormControl('', [Validators.required, Validators.maxLength(30), Validators.minLength(6), Validators.pattern("[a-zA-Z0-9.\\-_]{1,}@[a-zA-Z0-9.\\-]{2,}[.]{1}[a-zA-Z]{2,}"), this.noWhitespaceValidator]),
      'subject': new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      'message': new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(500), this.noWhitespaceValidator])
    })
  }

  public noWhitespaceValidator(control: FormControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      let removedSpaces = control.value.split(' ').join('');
      control.setValue(removedSpaces);
      return { required: true }
    }
    return null;
  }

  onSubmit() {
    this.globalService.showLoader();
    this.globalService.contactForm(this.contactUsForm.value).subscribe((res: any) => {
      this.formDirective?.resetForm();
      this.modalRef = this.modalService.show(this.infoModal, Object.assign({}, { class: 'modal-dialog modal-dialog-centered font-segoe-ui remove-job' }));
      this.globalService.hideLoader();
    }, (error) => {
      this.globalService.hideLoader();
    });
  }

  nagivateBasedOnCondition() {
    this.modalRef.hide();
    let userDetails = this.storageService.getItems('userDetails');
    let path = userDetails ? (userDetails.roleId == 1) ? 'worker/search' : 'business/home/dashboard' : 'home/workers';
    this.route.navigate([`./${path}`])
  }

}
