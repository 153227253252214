<div *ngIf="showForm" class="d-flex align-items-center justify-content-center">
  <div class="shadow p-10 mb-5 bg-white rounded center-child-div">
    <h3 class="text-center pb-5">Reset Password</h3>
    <form [formGroup]="passwordForm" #PasswordDirective="ngForm" (ngSubmit)="passwordForm.valid && savePassword()">
      <div class="form-group">
        <div class="position-relative">
          <i class="position-absolute password-eye fa" (click)="togglePassword()"
            [ngClass]="{'fa-eye-slash ': !isPasswordVisible, 'fa-eye':isPasswordVisible}"></i>
          <input [type]="isPasswordVisible?'text':'password'" autocomplete="on" class="form-control pr-9"
            placeholder="Password" name="password" formControlName="password">
          <ng-container
            *ngIf="(PasswordDirective.submitted)  && passwordForm.get('password').invalid">
            <small class="text-danger" *ngIf="passwordForm.get('password').errors.required">
              Field is required
            </small>
            <small class="text-danger"
              *ngIf="!passwordForm.get('password').errors.required && passwordForm.get('password').errors.minlength">
              Minimum 8 characters
            </small>
            <small class="text-danger"
              *ngIf="!passwordForm.get('password').errors.required && passwordForm.get('password').errors.maxlength">
              Maximum 50 characters
            </small>
          </ng-container>
        </div>
      </div>
      <div class="form-group">
        <div class="position-relative">
          <i class="position-absolute password-eye fa" (click)="toggleConfirmPassword()"
            [ngClass]="{'fa-eye-slash ': !isConfirmPasswordVisible, 'fa-eye':isConfirmPasswordVisible}"></i>
          <input [type]="isConfirmPasswordVisible?'text':'password'" autocomplete="on" class="form-control pr-9"
            placeholder="Confirm Password" formControlName="confirmPassword" name="confirmPassword">
          <ng-container
            *ngIf="(PasswordDirective.submitted)  && passwordForm.get('confirmPassword').invalid">
            <small class="text-danger" *ngIf="passwordForm.get('confirmPassword').errors.required">
              Field is required
            </small>
            <small class="text-danger"
              *ngIf="!passwordForm.get('confirmPassword').errors.required && passwordForm.get('confirmPassword').errors.NotEqual">
              Password must match
            </small>
            <small class="text-danger"
              *ngIf="!passwordForm.get('confirmPassword').errors.required && !passwordForm.get('confirmPassword').errors.NotEqual && passwordForm.get('confirmPassword').errors.minlength">
              Minimum 8 characters
            </small>
            <small class="text-danger"
              *ngIf="!passwordForm.get('confirmPassword').errors.required && !passwordForm.get('confirmPassword').errors.NotEqual && passwordForm.get('confirmPassword').errors.maxlength">
              Maximum 50 characters
            </small>
          </ng-container>
        </div>
      </div>
      <div class="form-group mb-8">
        <button type="submit" class="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">RESET PASSWORD
        </button>
      </div>
    </form>
  </div>
</div>