<!-- jobDetails-section -->
<div class="jobDetails-section bg-default pt-md-30 pt-sm-25 pt-23 pb-md-27 pb-sm-20 pb-17">
    <div class="container w-90">
    <div class="row">
        <div class="col-xl-6 col-md-7 pr-xl-15" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
        <div class="">
            <h3 class="font-size-9 font-weight-bold mb-7 mb-lg-13">Frequently Asked Questions</h3>
            <p class="font-size-4 mb-2">Not seeing your question here?</p>
            <a routerLink="../help" class="font-size-3 font-weight-bold text-green text-uppercase">Chat with us</a>
        </div>
        </div>
        <div class="col-xl-6 col-md-11" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
        <div class="faq-content pt-lg-4 pt-6">
            <div class="accordion rounded-10 border-green border-top-5 pl-1" id="accordionExample">
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-1">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-1" aria-expanded="true" aria-controls="collapse2-1">
                    What is Temp?
                </button>
                </div>
                <div id="collapse2-1" class="collapse pr-7 show" aria-labelledby="heading2-1" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    Temp is a marketplace that connects businesses with short term workers.
                </div>
                </div>
            </div>
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-2">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-2" aria-expanded="false" aria-controls="collapse2-2">
                    When do I get paid?
                </button>
                </div>
                <div id="collapse2-2" class="collapse pr-7" aria-labelledby="heading2-2" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    Payment normally takes about 5-7 business days. We pay as soon as the job is complete, or if the job extends over multiple weeks we pay weekly. Payment is subject to timesheet approval.
                </div>
                </div>
            </div>
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-3">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-3" aria-expanded="false" aria-controls="collapse2-3">
                    How do ratings work?
                </button>
                </div>
                <div id="collapse2-3" class="collapse pr-7" aria-labelledby="heading2-3" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    Once a job is completed, both worker and the business rate each other on four different qualities, there is also the ability to leave some feedback. Ratings help match good businesses with good workers.
                </div>
                </div>
            </div>
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-4">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-4" aria-expanded="false" aria-controls="collapse2-4">
                    Can I back out of a job?
                </button>
                </div>
                <div id="collapse2-4" class="collapse pr-7" aria-labelledby="heading2-4" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    Once hired you are locked into the job so make sure you are available for that date and you are sure about the commitment.
                </div>
                </div>
            </div>
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-5">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-5" aria-expanded="false" aria-controls="collapse2-5">
                    What if I am unable to make a job?
                </button>
                </div>
                <div id="collapse2-5" class="collapse pr-7" aria-labelledby="heading2-5" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    In the event you are sick or unable to make a job you can click the report option on your job and then select “I am unable to make this job” you must give a reason for your absence. Failure to turn up to 3 jobs will result in a ban from the Temp platform.
                </div>
                </div>
            </div>
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-6">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-6" aria-expanded="false" aria-controls="collapse2-6">
                    What if I am asked to do overtime?
                </button>
                </div>
                <div id="collapse2-6" class="collapse pr-7" aria-labelledby="heading2-6" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    If you are asked to complete additional hours please ensure that the Job provider offers this through the Temp app. This ensures you will be paid for any additional work.
                </div>
                </div>
            </div>
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-7">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-7" aria-expanded="false" aria-controls="collapse2-7">
                    Do I need to pay taxes?
                </button>
                </div>
                <div id="collapse2-7" class="collapse pr-7" aria-labelledby="heading2-7" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    Workers on the Temp platform function as contractors, this means you are required by law to pay your own taxes.
                </div>
                </div>
            </div>
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-8">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-8" aria-expanded="false" aria-controls="collapse2-8">
                    Who do I work for when using Temp?
                </button>
                </div>
                <div id="collapse2-8" class="collapse pr-7" aria-labelledby="heading2-8" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    When you work using the Temp platform you act as an independent contractor who is contracted to the business which has chosen to hire you.
                </div>
                </div>
            </div>
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-9">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-9" aria-expanded="false" aria-controls="collapse2-9">
                    What if my worker does not turn up?
                </button>
                </div>
                <div id="collapse2-9" class="collapse pr-7" aria-labelledby="heading2-9" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    If for some reason your worker does not turn up, you must report it. Temp takes attendance very seriously. Once reported, Temp will review the case and decide if the worker had a legitimate reason for being absent.
                </div>
                </div>
            </div>
            <div class="border-bottom overflow-hidden">
                <div class="mb-0 border-bottom-0" id="heading2-10">
                <button class="btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset tcolor mt-n2" type="button" data-toggle="collapse" data-target="#collapse2-10" aria-expanded="false" aria-controls="collapse2-10">
                    How do payments work in Temp?
                </button>
                </div>
                <div id="collapse2-10" class="collapse pr-7" aria-labelledby="heading2-10" data-parent="#accordionExample">
                <div class="mt-n3 font-size-4 text-gray focus-reset tcolor scolor pb-7 pr-7 pt-6">
                    We take payment upfront before the job is posted. Stripe our payment provider then holds the payment in trust until the job is complete. Once timesheets are approved the payment is released to the worker. If the worker does not meet the advertised hours then the business is refunded the difference. If the worker completes overtime then an additional charge will be made for the additional hours worked. See our Term and Conditions for more details.
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>