<app-alert></app-alert>
<!-- <router-outlet *ngIf="hasAccess"></router-outlet> -->
<router-outlet></router-outlet>
<app-loader></app-loader>

<!-- <div class="card card-body" style="max-width: 400px;">
    To Gain Access Please Sign In To Continue
    <div class="mt-2">
        <label for="email"
                  class="font-size-4 text-black-2 font-weight-semibold line-height-reset">Username</label> 
        <input type="text" id="email" name="email" [(ngModel)]="user.username" class="form-contorl"/>
        <label for="password"
                  class="font-size-4 text-black-2 font-weight-semibold line-height-reset">Pasword</label>
        <input type="password" [(ngModel)]="user.password" id="password" name="password" class="form-contorl"/>
    </div>
    <div class="mt-2 d-flex justify-content-center">
        <button class="btn btn-success btn-sm ml-2 w-25" (click)="signIn()">Sign In</button>
    </div>
</div> -->