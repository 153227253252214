import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  showForm = false;
  passwordForm: FormGroup;
  token: any;
  isPasswordVisible = false;
  isConfirmPasswordVisible = false;

  constructor(private activatedRoute: ActivatedRoute, public appService: AppService,
    private router: Router, private globalService:GlobalService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.token) {
        this.token = params.token;
        this.validateToken();
      } else {
        this.globalService.addAlert('danger','Invalid URL');
        this.router.navigate(['./homasdasasd']);
      }
    });
  }

  validateToken() {
    this.appService.validateToken(this.token).subscribe((res) => {
      this.showForm = true;
      this.initPasswordForm();
    }, (error) => {
      this.globalService.addAlert('danger','Invalid URL');
      this.router.navigate(['./home']);
    })
  }

  initPasswordForm() {
    this.passwordForm = new FormGroup({
      'password': new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(50),this.noWhitespaceValidator]),
      'confirmPassword': new FormControl(null, [Validators.required, this.checkPasswordEqual.bind(this), Validators.minLength(8), Validators.maxLength(50),this.noWhitespaceValidator]),
    })
  }

  public noWhitespaceValidator(control: FormControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      let removedSpaces = control.value.split(' ').join('');
      control.setValue(removedSpaces);
      return { required: true }
    }
    return null;
  }

  checkPasswordEqual(control: FormControl): ValidationErrors | null {
    if (typeof this.passwordForm != "undefined")
      return control.value === this.passwordForm.get("password").value ? null : {
        NotEqual: true
      };
    return null;
  }

  savePassword() {
    this.globalService.showLoader();
    let payload = { token: this.token, password: this.passwordForm.get("password").value }
    this.appService.updatePassword(payload).subscribe((res) => {
      this.passwordForm.reset();
      this.showForm = false;
      this.globalService.hideLoader();
      this.globalService.addAlert('success','Password sucessfully updated');
      this.router.navigate(['./home']);
    }, (error) => {
      this.globalService.hideLoader();
      this.globalService.addAlert('danger',error.message);
    })
  }

  togglePassword(){
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPassword(){
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

}
