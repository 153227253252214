<div class="site-wrapper overflow-hidden ">
    <!-- 404-page-section -->
    <!-- <div class="header pt-11 pos-abs-tl w-100">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <span class="headerColor pb-10">QUICKIES</span>
                </div>
            </div>
        </div>
    </div> -->
    <div class="404-page bg-default min-h-100vh flex-all-center pt-lg-15 pt-xxl-17 pt-27 pb-lg-0 pb-18">
        <div class="container w-90">
            <div class="row justify-content-center">
                <div class="col-lg-7 px-lg-9">
                    <!-- card start -->
                    <div class="card-404 text-center" data-aos="zoom-in" data-aos-duration="800" data-aos-once="true">
                        <!-- card image start -->
                        <img src="../../../assets/image/svg/404.svg" alt="" class="w-100 px-9">
                        <!-- card image end -->
                        <!-- card-icon start -->
                        <div class="404-texts pt-14">
                            <h3 class="card-title font-size-9 font-weight-bold">Page is not found!</h3>
                            <!-- card-texts start -->
                            <p
                                class="card-text font-size-4 px-xxl-28 px-xs-10 px-sm-13 px-lg-13 px-md-28 px-xl-22 px-0 mb-11">
                                To Smart</p>
                            <!-- card-texts end -->
                            <a (click)="back()"
                                class="btn btn-green btn-h-60 text-white rounded-5 w-180 m-auto text-uppercase">Back to
                                home</a>
                        </div>
                    </div>
                    <!-- card end -->
                </div>
            </div>
        </div>
    </div>
</div>