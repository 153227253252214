import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  email = 'careers@temp.org.nz';

  constructor() { }

  ngOnInit(): void {
  }

  getMail(){
    navigator.clipboard.writeText('careers@temp.org.nz');
  }
}
