import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
})

export class AppService {


    constructor(private _http: HttpClient) { }

    signUp(payload: any) {
        return this._http.post(`${environment.API_URL}user/save`, payload);
    }

    login(payload) {
        return this._http.post(`${environment.API_URL}token/generate-token`, payload);
    }

    getUserDetails(userId: any) {
        return this._http.get(`${environment.API_URL}user/get?id=${userId}`);
    }

    resetPassword(emailId) {
        return this._http.post(`${environment.API_URL}user/resetpassword?email=${emailId}`, '');
    }

    validateToken(token) {
        return this._http.get(`${environment.API_URL}user/validatetoken?token=${token}`);
    }

    updatePassword(payload) {
        return this._http.post(`${environment.API_URL}user/updatepassword`, payload);
    }
    
    showApplication(payload) {
        return this._http.post(`${environment.API_URL}token/show-app`, payload);
    }

    getPlans() {
        return this._http.get(`${environment.API_URL}public/all-plans`);
    }
    
    saveCardToken(payload) {
        return this._http.post(`${environment.API_URL}public/create-card-token`,payload);
    }

    getSubscription(payload){
        return this._http.post(`${environment.API_URL}public/create-subscription`,payload)
    }
    
    getSubscriptionForCard(payload){
        return this._http.post(`${environment.API_URL}public/create-subscription-element`,payload)
    }

    getSetupIntentForCard(payload){
        return this._http.post(`${environment.API_URL}public/save-card-casual`,payload)
    }
    
    getEmailCount(email){
        return this._http.get(`${environment.API_URL}public/check-user-existing?email=${email}`)
    }

    createBankAccountToken(bankacct){
        return this._http.get(`${environment.API_URL}public/create-bank-account-token?bankacct=${bankacct}`)
    }

    getCompanyUniqueness(id){
        return this._http.get(`${environment.API_URL}public/checkcompanyid?id=${id}`)
    }
}