import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard.service';
import { Role } from './_models/enum';
import { PageNotFoundComponent } from './_shared-components/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FaqComponent } from './_shared-components/faq/faq.component';
import { ContactUsComponent } from './_shared-components/contact-us/contact-us.component';
import { AboutUsComponent } from './_shared-components/about-us/about-us.component';
import { BlogComponent } from './_shared-components/blog/blog.component';
import { CareersComponent } from './_shared-components/careers/careers.component';
import { TermsConditionsComponent } from './_shared-components/terms-conditions/terms-conditions.component';
import { SiteUnavailableComponent } from './site-unavailable/site-unavailable.component';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  }, {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'worker',
    loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
    canActivate: [AuthGuard],
    data: { roles: [Role.Employee] }
  },
  {
    path: 'business',
    loadChildren: () => import('./employer/employer.module').then(m => m.EmployerModule),
    canActivate: [AuthGuard],
    data: { roles: [Role.Employer] }
  },{
    path: 'resetpassword/:token',
    component:ResetPasswordComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'career',
    component: CareersComponent
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent
  },
  {
    path: 'site-unavailable',
    component: SiteUnavailableComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy',scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
