<div class="bg-default-2 pt-25 pb-13 pb-xxl-32 contact-us">
    <div class="container px-md-0 w-90">
        <div class="row justify-content-center">
            <div class="px-4 contact-us-wrap">
                <h2 class="font-size-9 font-weight-bold mb-8 text-center">Contact Us</h2>
                <div class="contact-us-form bg-white shadow-8 rounded-4 pt-7 pb-8 px-5 px-md-9">
                    <form name="ContactUS" [formGroup]="contactUsForm" #formDirective="ngForm"
                        (ngSubmit)="!contactUsForm.invalid && onSubmit()">
                        <fieldset>
                            <div class="row">
                                <div class="form-group col-12">
                                    <label label>Your Name</label>
                                    <input type="text" name="name" class="form-control h-px-48" placeholder="John Doe"
                                        formControlName="name">
                                    <ng-container
                                        *ngIf="(formDirective.submitted)  && contactUsForm.get('name').invalid">
                                        <small class="text-danger" *ngIf="contactUsForm.get('name').errors.required">
                                            Field is required
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="!contactUsForm.get('name').errors.required && contactUsForm.get('name').errors.maxlength">
                                            Maximum 50 Characters
                                        </small>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6 col-12">
                                    <label>Email</label>
                                    <input type="email" name="email" class="form-control h-px-48"
                                        placeholder="Example@gmail.com" formControlName="email">
                                    <ng-container
                                        *ngIf="(formDirective.submitted)  && contactUsForm.get('email').invalid">
                                        <small class="text-danger" *ngIf="contactUsForm.get('email').errors.required">
                                            Field is required
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="!contactUsForm.get('email').errors.required && contactUsForm.get('email').errors.pattern">
                                            Invaild email address
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="!contactUsForm.get('email').errors.required && !contactUsForm.get('email').errors.pattern && contactUsForm.get('email').errors.maxlength">
                                            Maximum 30 Characters
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="!contactUsForm.get('email').errors.required && !contactUsForm.get('email').errors.pattern && contactUsForm.get('email').errors.minlength">
                                            Minimum 6 Characters
                                        </small>
                                    </ng-container>
                                </div>
                                <div class="form-group col-md-6 col-12 h-50 contactus">
                                    <label>Subject</label>
                                    <select id="selectreason" name="reason" data-size="7"
                                        class="selectpicker form-control px-2 border" formControlName="subject">
                                        <option>Help</option>
                                        <option>Sales</option>
                                        <option>General inquiries</option>
                                    </select>
                                    <ng-container
                                        *ngIf="(formDirective.submitted)  && contactUsForm.get('subject').invalid">
                                        <div>
                                            <small class="text-danger pl-md-1 pl-0 word-wrap errorMsg"
                                                *ngIf="contactUsForm.get('subject').errors.required">
                                                Field is required
                                            </small>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12">
                                    <label>Message</label>
                                    <textarea class="form-control h-px-144" name="message"
                                        placeholder="Type your message" rows="7" formControlName="message" ></textarea>
                                    <ng-container
                                        *ngIf="(formDirective.submitted)  && contactUsForm.get('message').invalid">
                                        <small class="text-danger" *ngIf="contactUsForm.get('message').errors.required">
                                            Field is required
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="!contactUsForm.get('message').errors.required && contactUsForm.get('message').errors.minlength">
                                            Minimum 5 Characters
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="!contactUsForm.get('message').errors.required && contactUsForm.get('message').errors.maxlength">
                                            Maximum 500 Characters
                                        </small>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-12 text-center px-0 mt-1">
                                <button type="submit" class="btn btn-success w-100 btn-lg px-8 py-7 text-uppercase">SEND
                                    NOW</button>
                            </div>
                        </fieldset>
                        <div class="d-flex contact-us-email-wrap justify-content-center">
                            <span class="contact-icon mr-7 mt-6 mt-md-7"></span>
                            <div class="contact-email text-center font-weight-semibold pt-md-8 pt-6">Or contact us at
                                <span class="text-green">support@temp.org.nz</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #removetemplte>
    <!-- <div class="modal-dialog modal-dialog-centered save-template modal-xl"> -->
    <div class="modal-content">
        <div class="modal-header border-0 h4 m-0 pb-4 pt-md-10 pt-9 px-md-12 px-6 font-size-sm-15px">
            Thanks for your submission!
        </div>
        <div class="modal-body px-0 container pt-0 px-md-12 px-6 pb-md-9 pb-4 mx-0 font-weight-semibold mw-100">
            <div class="w-90 font-size-20px font-size-sm-15px">We will be in contact as soon as possible. Please also
                check out our help guides and FAQ.</div>
            <div class="row mx-0 mt-md-11 mt-8 justify-content-center">
                <button type="button"
                    class="btn btn-primary btn-lg h-px-48 ml-md-4 ml-1 float-right rounded-5 text-uppercase"
                    (click)="nagivateBasedOnCondition()">ok</button>
            </div>
        </div>
    </div>
    <!-- </div> -->
</ng-template>