<div class="bg-white pt-25 pb-13 pb-xxl-32 about-us">
    <div class="container w-90">
        <div class="row justify-content-center about-us-wrap mw-px-800 mx-auto">
            <h2 class="font-size-8 font-weight-bold mr-auto">About us</h2>
            <!-- section 1-->
            <section class="section-1">
                <div class="about-us-content font-weight-semibold mb-9">
                    <p class="font-weight-semibold">Temp was founded to help students earn money while at university. Being a student, it can be tough to earn and study at the same time. Most students find it hard to commit to a job with an ever changing schedule. Temp aims to give people the opportunity to work when it suits them.</p>
                </div>
                <div class="about-us-img w-100">
                    <img src="../../../assets/image/png/group-of-people-formatted.png" alt="group-of-people-formatted" class="w-100"/>
                </div>
                <p class="pt-6 font-weight-semibold">Interested in working at Temp. See our <a class="text-green" routerLink="../career">Careers</a> page.</p>
            </section>

            <!-- section 2-->
            <section class="section-2 pt-13">
                <div class="card row mx-0 border-0 py-md-7 py-6">
                    <h2 class="col-12 d-md-none font-size-32px mb-6">Our mission</h2>
                    <div class="left-content col-md-5 col-12 pl-md-7">
                        <img src="../../../assets/image/png/Headshot-formatted.jpg" alt="section-2" class="w-100" />
                    </div>
                    <div class="right-content col-md-7 col-12 my-md-auto my-5 pr-md-15">
                        <h2 class="mb-8 d-none d-md-block">Our mission</h2>
                        <p class="font-weight-semibold"><p>Temp was born out of desire to reinvent how and when we work, as the 9-5 doesn&rsquo;t work for everyone. Temp&rsquo;s mission is to create a platform which streamlines the hiring process while also giving people new ways to earn. We want to help connect businesses with short term, short notice workers, and we want everyone to have access to flexible work opportunities. Temp aims to provide businesses with reliable trusted candidates based on ratings from past jobs. For workers this means having the flexibility to fit work into a busy schedule while still being able to develop a positive career profile</p>
                        <p>- Jared Crowle, Founder &amp; CEO</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>